import React from 'react';
import { Badge, Box, Group, Image, Paper, Stack, Text, useMantineTheme } from '@mantine/core';
import { CalendarEvent, Location, Users } from 'tabler-icons-react';
import { DateTime } from 'luxon';
import Tournament from '~shared/Tournament';

export type TournamentCardProps = {
    tournament: Tournament,
    width?: number | string,
    imageHeight?: number | string,
}

export default function TournamentCard(props: TournamentCardProps) {
    const theme = useMantineTheme();
    const image = props.tournament.images.find(i => i.type === 'profile');
    const cardRadius = theme.spacing.sm;

    return (
        <Paper
            shadow="sm"
            p="md"
            radius={cardRadius}
            sx={{
                overflow: 'hidden',
                height: '100%',
            }}
            component='a'
            href={`https://start.gg/${props.tournament.slug}`}
            target="_blank"
            title="Click to view on start.gg"
        >
            <Group
                noWrap
                align="center"
                sx={{
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        width: '175px',
                        maxWidth: '40%',
                        aspectRatio: '1 / 1',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                        borderRadius: cardRadius,
                        flexShrink: 0,
                    }}
                >
                    {image &&
                        <Image
                            src={image.url}
                            radius={cardRadius}
                        />
                    }
                </Box>
                <Stack
                    justify="space-evenly"
                    align="start"
                    sx={{
                        height: '100%',
                    }}
                    spacing={5}
                >
                    <Text weight={700}>{props.tournament.name}</Text>
                    <Text size="sm">
                        <CalendarEvent size={theme.fontSizes.sm} />
                        &nbsp;
                        {DateTime.fromSeconds(props.tournament.startAt)
                            .toLocaleString(DateTime.DATETIME_MED)}
                    </Text>
                    <Text size="sm">
                        <Location size={theme.fontSizes.sm} />
                        &nbsp;
                        {props.tournament.venueAddress}
                    </Text>
                    <Badge>
                        <Users size={theme.fontSizes.sm} />
                        &nbsp;
                        {props.tournament.numAttendees} Attendees
                    </Badge>
                </Stack>
            </Group>
        </Paper>
    );
}
