import React, { useEffect, useState } from 'react';
import './Startcal.css';
import { ActionIcon, Anchor, AppShell, Grid, Group, Header, Text, Tooltip, useMantineTheme } from '@mantine/core';
import Tournament from '~shared/Tournament';
import TournamentCard from './components/TournamentCard';
import { BrandTwitter, Calendar, Moon, Sun } from 'tabler-icons-react';

export type ColorScheme = 'light' | 'dark';

export type StartcalProps = {
    theme: ColorScheme,
    onSetTheme: (theme: ColorScheme) => void,
}

function Startcal(props: StartcalProps) {
    const [tournaments, setTournaments] = useState<Tournament[] | null>(null);
    const theme = useMantineTheme();

    useEffect(() => {
        fetch('/tournaments')
            .then(res => res.json())
            .then(data => setTournaments(data));
    }, []);

    return (
        <AppShell
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
                root: {
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
                },
            }}
            fixed
            header={
                <Header height={50} p="xs" sx={{alignItems: 'center'}}>
                    <Group>
                        <Text size='xl' weight={700} sx={{ flexGrow: 1 }}>
                            <Calendar />
                            &nbsp;Startcal
                        </Text>
                        <Text>
                            Made by&nbsp;
                            <Anchor
                                href="https://twitter.com/vektronssb"
                                target="_blank"
                            >
                                Vektron&nbsp;<BrandTwitter size={16}/>
                            </Anchor>
                        </Text>
                        <Text sx={{alignSelf: 'baseline'}}>
                            <Tooltip label="Switch theme">
                                <ActionIcon
                                    onClick={() => props.onSetTheme(props.theme === 'light' ? 'dark' : 'light')}
                                >
                                    {props.theme === 'light'
                                        ? <Sun size={24} />
                                        : <Moon size={24} />
                                    }
                                </ActionIcon>
                            </Tooltip>
                        </Text>
                    </Group>
                </Header>
            }
        >
            <Grid
                gutter="xs"
                align="stretch"
            >
                {
                    tournaments?.map((t, i) =>
                        <Grid.Col
                            key={i}
                            xs={12}
                            sm={6}
                            lg={4}
                            xl={3}
                        >
                            <TournamentCard
                                tournament={t}
                            />
                        </Grid.Col>
                    )
                }
            </Grid>
        </AppShell>
    );
}

export default Startcal;
