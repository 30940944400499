import React, { useEffect, useState } from 'react';
import { MantineProvider, useMantineTheme } from '@mantine/core';
import Startcal, { ColorScheme } from './Startcal';

export function App() {
    const themeFromStorage = localStorage.getItem('theme') as ColorScheme | null;
    const theme = useMantineTheme();
    const [colorScheme, setColorScheme] = useState<ColorScheme>(themeFromStorage ?? 'light');

    useEffect(() => {
        const color = colorScheme === 'light'
            ? theme.colors.gray[0]
            : theme.colors.dark[7];
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color);
        localStorage.setItem('theme', colorScheme);
    }, [colorScheme]);

    return (
        <MantineProvider theme={{ colorScheme }}>
            <Startcal
                theme={colorScheme}
                onSetTheme={setColorScheme}
            />
        </MantineProvider>
    );
}

export default App;
